.header {
    height: auto;
    text-align: center;
    background-image: url('../images/blob.png');
    background-repeat: no-repeat;
    background-position: center;
    font-size: 20px;
    background-size: cover;
    margin-top: 100px;
    height: auto;
    z-index: 99;
    
}

.header p {
    border-radius: 20px;;
    padding-top: 20%;
    padding: 1px;
    font-weight: 400;
    font-size: 25px;
    cursor: pointer;
    color: black;
}
/*
.header :hover {
    color: rgb(250, 245, 250);
}
*/
@media(max-width: 800px) {
    .header p{
        font-weight: 400;
        font-size: 22px;

    }
    .header {
        margin-top: 49px;       
    }
}