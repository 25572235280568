.css-1ymh205 {
    position: sticky;
    top: calc(var(--header-height) + 0rem);
    color: white;
    background-color: rgb(38, 38, 38);
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    
    padding-top: 20px;
    border-bottom: 1px solid rgb(229, 229, 229);
    margin-top: 100px;
}

.css-1l4w6pd {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    
}
.css-duea03 {
    line-height: 1;
    background-color: transparent;
    -webkit-appearance: none;
    font-style:oblique;
    position: relative;
    display: inline-block;
    color: white;
    cursor: pointer;
    font-size: 0.99rem;
    letter-spacing: 0.108rem;
    padding: 0px 0px 0.4rem;
    border-width: 0px;
  
    margin: 2rem;
}
.css-duea03 a {
    color: #eafbff;
    text-decoration: none;
}

.css-duea03  :hover {
    color: #5acefc;
}

.portada img {
    width: 100%;
    max-width: 500x;
    height: auto;
    max-height: 600px;
    border-radius: 20px;
    /*
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    /*background-image: url("../images/elvis.jpg");*/
    background-position: bottom center;
    background-size: cover;
}
.oma-underline {
    text-decoration: underline;
}
.card-hinnasto {
    margin-top: 10px;
    width: 70%;
}
.title-total p {
    width: 100%;
}
.hinnat-lista {
    width: 80%;
}
.hinnat-lista td {
    width: 100%;
}
th, td {
    padding: 2px;
    text-align: left;
    font-size: 14px;
    /*border-bottom: 1px solid #ddd;*/
    
}
tr:nth-child(even) {background-color: #5acefc;}

.perus-opetus p {
    font-size: 15px;
}

.title-total {
    padding: .4em 1em 2em 0em;
}
.title-total h2 {
    text-align: center;
    margin-top: 20px;
    font-weight: 400;
}

.muut-infot {
    display: flex;
    
    justify-content: space-between;
    padding-bottom: 30px;
    padding-top: 20px;

}
.muut-infot p {
    font-size: 14px;
}
.opintojen-lopettaminen {
    width: 100%;
}
.opintojen-lopettaminen p {
    max-width: 500px;
}
.opintojen-lopettaminen h2 {
    padding-bottom: 15px;
}
.opintojen-keskeyttäminen {
    width: 80%;
}
.opintojen-keskeyttäminen h2 {
    padding-bottom: 15px;

}
.yhteyis-hinnasto i {
    font-size: 30px;
    padding: 10px;
    
}
.select-color {
    /*background-color: rgb(255, 250, 245);*/
    
}
.perus-opetus {
    background-color: #f6feff;
}
.avoin-opetus {
    background-color: #f6feff;
}
.muskari-opetus {
    background-color: #f6feff;
}
.opintojen-ul {
    font-size: 14px;
    margin-left: 20px;
    max-width: 450px;
}

@media (max-width: 880px) {
     .css-1l4w6pd {
         display: flex;
         flex-direction: column;
         
     }
     .css-1ymh205 {
         padding-bottom: 2px;
         margin-top: 50px;
        
         
     }   
     .css-duea03 {
         margin: 0.5rem;
     }
     .title-total p {
         width: 100%;
     }  


}
@media (max-width: 880px) {
    .opisto-hinnat {
        display: flex;
        flex-direction: column;
    }
    .muut-infot {
        display: flex;
        flex-direction: column;
        
    }
    .hinnat-text p {
        max-width: 100%;
    }
    .hinnat-lista {
        width: 100%;
        padding-top: 20px;
    }
    .opintojen-lopettaminen  {
        padding-bottom: 26px;
        
    }
    .opintojen-keskeyttäminen {
        width: 100%;
    }
    .card-flex {
    
        
        width: 100% !important;
        height: 100%;
        flex-direction: column;
       
      }
      .card {
          width: 100%;
          max-width: 450px;
          max-height: 650px;
      }
      .card-hinnasto {
          width: 100%;
      }
      .card-hinnasto p {
          width: 100%;
      }
    
       

}