.main-nav {
    display: flex;
    flex-direction: column;
    z-index: 99;
    top: 0;
    width: 100%;
    position: fixed;
    background-color: white;
}


.nav-upper {
    display: flex;   
    align-items:center;
    border-style:outset;
    border-color: rgb(254, 254, 254);
    border-width: 0.1px;
}
.nav-upper h3:hover {
    color: rgb(122, 59, 182);
    cursor: pointer;
  }
.upper-logo {
    color: rgb(3, 3, 3);
    letter-spacing: 5px;
    text-transform: uppercase;
    font-style: oblique;
    font-weight: 300;
    font-size: 32px;
    line-height: 1,3;
    display: flex;
    justify-content: center;
    flex: 1;  
    padding-left: 10%;
}
.upper-logo img {
  width: 33px;
  height: 34px;
  margin-left: -10px;
  margin-top: 10px;
  
}
.upper-social {
  margin-top: 1px;
  font-size: 11px;
  padding-right: 3%;
  
  
  
}
.upper-social i {
  color: rgb(64, 66, 66);
  padding-left: 6px;
}


.own-nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    border-style:outset;
    border-color: rgb(255, 255, 255);
    border-width: 0.2px;
}


.nav-links {
    display: flex;
    justify-content: space-evenly;
    width: 70%;
    
}
.nav-links li {
    text-decoration: none;
    list-style: none;
    letter-spacing: 3px;
    font-size: 14px;
}

.nav-links a {
    color: rgb(5, 5, 5);
    text-decoration: none;
    letter-spacing: 3px;
    font-size: 14px;

}


/* Dropdowns */
.dropdown {
    float: left;
    overflow: hidden;
    margin-top: 0px;
    
}
  
  .dropdown .dropbtn {
    font-size: 17px;    
    border: none;
    outline: none;
    color: rgb(0, 0, 0);
    padding: 14px 12px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
    cursor: pointer;
    
  }
  .dropdown-content a {
    float: none;
    color: black;
    padding: 10px 10px;
    text-decoration: none;
    display: block;
    text-align: left;
    color: black;
    
  }
  .dropdown-content {
    display: none;
    position: absolute;
   
    background-color: rgb(255, 255, 255);
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 11;
    border-radius: 8px;
  }
  /* Onclik button show */
  .show {
    display: block;
  }
  .under {
    text-decoration: underline;
   
  }
  
  
  
  .topnav a:hover, .dropdown:hover .dropbtn {
   /* background-color: #555; */
    color: blueviolet;
  }
  
  
  .dropdown-content a:hover {
  
  
    color: rgb(116, 114, 114);
  }

  .burger {
    display: none;
    cursor: pointer;
    padding-top: 4px;
}
.line3 {
  width: 20px !important;
}

.burger div {
    
    width: 25px;
    height: 3px;
    background-color: rgb(8, 8, 8);
    margin: 5px;
    transition: all 0.3s ease;
}
 /* Animate burger icon */
.toggle .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
}
.toggle .line2 {
    opacity: 0;
}
.toggle .line3 {
    width: 25px !important;
    transform: rotate(45deg) translate(-5px, -6px);
}
/* Social show */
.social2 {
    display: none;
}
@media screen and (max-width: 1200px ) {
  .nav-links {
    justify-content: space-around !important;
    width: 90% !important;
    
  }
 
}
@keyframes mymove {
    from {top: -100px;}
    to {top: 0px;}
  }

@media screen and (max-width: 910px){
    .nav-upper {
        justify-content: space-between !important;
    }

    button {
      padding: 0;
      border: none;
    }
    
    .upper-social {
      display: none !important;
    } 
    
    .upper-logo {
      flex: 0 !important;
      padding-left: 2% !important;

    }
  
    .dropdown-content a {
      float: none;
      color: black;
      margin-top: 2px;
      font-size: 16px;
      text-decoration: none;
      display: block;
      text-align: center;
      
      
      
    }
    .dropdown .dropbtn {
      font-size: 22px;
      /* position: unset; */    
      font-weight: 400;
      
      padding: 10px;
      /*
      float: none;
      border: none;
      outline: none;
      */

      margin-top: 0px;
      color: black;
      /*padding: 14px 16px;*/
    
      cursor: pointer;
      
    }
    .nav-links li {
      padding: none;
      
      /*background-color: rgb(249, 248, 248); */
    }
    .nav-links {
        position: fixed;
        right: 0px;
        color: black;
        top: 0px;
        padding-top: 70px; 
        /*padding-top: 90px; */
        
        min-height: 100%;
        max-height: none !important;
        
        background-color: rgb(255, 255, 255);
        z-index: 10;
        /*display: flex;*/
        justify-content: left;
        opacity: 1;
        text-align: center;
        width: 100% !important;
        flex-direction: column;
        
        transform: translate(100%);
        transition: transform 0.5s ease-in;
    }
   
      .dropdown-content a {
        text-align: center;
        color: rgb(20, 20, 20);
        
      }
      .dropdown-content a:hover {
  
  
        color: rgb(109, 105, 105);
      }
      .logo {
        color:rgb(5, 5, 5);
        letter-spacing: 5px;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 32px;
        line-height: 1.4;
        z-index: 22;
      
    }
   
      .dropdown-content {
       
        
        height: auto !important;
        
        width: 100%;
        left: 0;
        text-align: left;
        
     
        position: relative; 
       
        background-color: rgb(255, 255, 255);
       
       
        z-index: 22;
      }
      .burger {
          display: block;
          
          color: white;
          z-index: 12;
          padding-right: 10px;
      }
      .burger div {
        z-index: 22;
        color: wheat;
      }
      /* Social show */
  .social2 {
    display: none;
  }
  
  .social2 a {
    margin: 0 5px;
    color: blueviolet;
    
  }
   
   
}
.nav-active {
    transform: translate(0%);
}
