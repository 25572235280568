.oppilaalle {
    padding-top: 8%;
}
.oppilaalle-title {
    text-align: left;
    padding: 30px 20px;
}
.center-text {
    text-align: center;
}

.list-oppilaalle {
    text-align: center;

}
.border-kuva img {
    border-radius: 10px;
}
.slide-flex {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: left;
    
}
.slide-flex-center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   
}


.slide-flex p {
    max-width: 700px;
}

.slide-four-img {
    width: 55%;
}

.slide-flex .title {
    padding-left: 30px;
    max-width: 700px;
    text-align: left;
}
.slide-flex .title h2 {
    font-size: 24px !important;
}
.slide-flex .title p {
    font-size: 16px !important;
}
.slide-flex-center .title h2 {
    font-size: 24px !important;
}
.slide-flex-center .title p {
    font-size: 16px !important;
}
.title-padding {
    padding: 20px;
}
.low-title {
    background-color: rgb(237, 130, 130);
    height: 100px;
    color: black;
    padding-left: 20px;
    padding-top: 20px;
    text-align: center;
}

.slide-tree {
    color: black;
    width: 100%;
    height: 80vh
    
    /*
    background-image: url('../images/kuvituskuva.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 199%;
    */
}
.slide-four {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80vh;
}
.slide-flex-center .slide-img {
    width: 100%;
    height: 80vh;
}
.slide-img img {
    height: 20px;
}

.slide-one {
    color: white;
    text-align: center;
    height: 80vh;
    
    width: 100%;
    background-image: url('../images/Rumpali.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    
}
.slide-two {
    background-image: url('../images/orkesteir2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    height: 80vh;
    width: 100%;
}



.swiper {
    width: 100%;
    height: 75vh;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @media (max-width: 800px) {
    .slide-flex-center {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
       
    }
    .slide-flex-center .slide-img {
        width: 100%;
        height: 34vh;
    }
    .slide-flex .title {
        padding-left: 0px;
        max-width: 700px;
        text-align: left;
    }
    .swiper {
        width: 100%;
        height: 85vh;
    }
    .low-title {
        background-color: rgb(206, 81, 81);
        height: 100px;
        color: #fff;
        padding-left: 0px;
        padding-top: 20px;
    }
    .low-title {
        display: none;
    }
    .slide-four {
        height: 92vh;
    }
    .slide-one {
        height: 95vh;
    }
    .slide-tree {
        height: 92vh;
    }
    .slide-two {
        height: 92vh;
    }
    .title-padding {
        padding: 5px !important;
    }
    .slide-four-img {
        width: 0%;
    }
  }