.toimipisteet {
    background-color: rgb(255, 255, 255);
    
}
.toimisto-all {
    
    
    
    height: auto;
    background-color: rgb(26, 24, 24);
   

    
}
.toimisto-all h3 {
    font-size: 30px;
    font-weight: 400;
    padding-top: 4px;
}
.toimisto-allImage {
    width: 100%;
    padding-top: 0px;
   

}
.yhteyis-hinnasto i {
    font-size: 30px;
    padding: 10px;
    
}
.toimisto-allImage img {
    width: 100%;
    max-height: 422px;
    object-fit: cover;
    border-radius: 20px;
}


.toimisto-allText {
    padding: 0px 0px 0px 0px;
    text-align: center;
    width: 100%;
    max-height: 500px;
    padding-top: 8px;
    background-color: rgb(255, 255, 255);
}
.toimisto {
    text-align: center;   
    margin-top: -50px;
   
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 20px;
   
}
.toimisto-all h3 {
    
    padding-top: 0px;
}
.toimisto h3 {
    font-size: 25px;
    font-weight: 400;
    margin-top: 20px;
}
.rehtorit {
    display: flex;
    width: 100%;
    height: 300px;
    background-color:#e7e9ea;


}

.card {
    
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    
    transition: 0.3s;
    
    background-color: #f8f8f8;
    width: 32%;
    border-radius: 30px;
    
    margin-left: 8px;
}
.card-color {
    background-color: #ececec;
    border-radius: 30px;
}
.card img {
    height: 240px;
    border-radius: 10px 10px 0px 0px;
    margin-top: 0px;
}
  
.card:hover {
    
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    
}



.toimisto-flex {
    display: flex;
    justify-content: space-evenly;
}
.rehtori-own {
    margin-top: 10px;
    width: 100%;
    height: 200px;   
    background-color: #f8f8f8;
    border-radius: 0 0 20px 20px;
}
.rehtori-own h3 {
    padding: 8px;
}


.toimisto-oma {
    width: 100%;
    background-color:#7cdaff;
}

.toimisto-own {
    width: 100%;
    height: 200px;
}
.vara-oma  {
    width: 100%;
    margin-top: 20px;
   
}
.vara-oma img {
    height: 300px;
    
}

.map-flex h4 {
   color: rgb(15, 15, 15);
   text-align: center;
   font-weight: 400;
   font-size: 30px;
   padding-top: 0px;
   padding-bottom: 0px;

}

.container-map {
    padding-bottom: 20px;
}
.toimisto-jp {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    /*padding: 0 3px;
    -ms-flex: 30%; /* IE10 */
    flex: 30%;
}
.toimisto-jp h3 {
    max-width: 400px;
    padding-bottom: 0px;
}
.toimisto-tu h3 {
    max-width: 300px;
}
.toimisto-jp p {
    max-width: 300px;
}
.toimisto-tu p {
    max-width: 300px;
}

.toimisto-tu {
   
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    -ms-flex: 30%; /* IE10 */
    flex: 30%;
}
.toimisto-tu h3 {
    font-weight: 400;
}

.toimistot {
    display: -ms-flexbox; /* IE10 */
    display: flex;
   -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 20px;
    margin-top: 0px;
    padding-bottom: 20px;
    
    
    

    text-align: left;
}
.toimistot img {
    margin-top: 10px;
    height: 250px;
    width: 90%;
}
.konsertti-tilat {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
    -ms-flex: 20%; /* IE10 */
    flex: 20%;
}
.kartta-button {
  background-color: rgb(228, 63, 219);
  border: none;
  color: white;
  border-radius: 4px;
  padding: 10px 11px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0px px;
  cursor: pointer;
  margin-bottom: 20px;
}

.map-list {
    margin-top: 30px;
    display: flex;
    justify-content: left;
    
    padding-bottom: 30px;
    
    

}
.map-listSmall {
    margin-right: 30px;
}

.toimipisteet h2 {
    font-weight: 300;
    font-size: 40px;
}

@media (max-width: 800px) {
    .toimistot {
        display: flex;
        flex-direction: column;
        
    }
    .card img {
        height: 230px;
    }
    .card {
        height: auto;
        margin-bottom: 18px;
    }
    .rehtori-own {
        margin-bottom: 30px;

    }
    .rehtori-own h3 {
        padding: 0px !important;
        font-size: 22px;
    }
    .toimisto-allText {
        text-align: left;
        max-height: none !important;
    }
    .toimisto {
        margin-top: 0px;
    }
    .toimisto {
        display: flex;
        flex-direction: column !important;
    }
    .toimistot img {
        width: 100% !important;
        height: auto;
        border-radius: 9px;
    }
    .toimisto-jp h3 {
        max-width: 100%;
        padding-bottom: 10px;
    }
    .toimisto-tu h3 {
        max-width: 100%;
    }
    .rehtorit {
        display: flex;
        flex-direction: column;
        
    }
    .toimisto h3 {
        margin-top: 0px !important;
    }
    .toimisto-flex {
        display: flex;
        flex-direction: column;
    }
    .rehtori-own {
        margin-top: 0px !important;
        background: none;
        height: 100px !important;
    }
    .toimisto-oma {
        background: none;
        text-align: center;
        padding-top: 0px;
        margin-bottom: 20px;
    }
    .toimisto-oma p {
        max-width: 100%;
        
    }
    .toimisto-own {
        height: auto;
    }
    .toimipisteet h2 {
        font-weight: 200;
        font-size: 27px;
        text-align: center !important;
    }
    .toimistot img {
        height: 260px !important;
    }
    .map-flex h4 {
        text-align: left;
    }
}
@media (max-width: 900px) {
    .toimisto-all {
        
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: none;
    }
    .toimisto-allImage {
        width: 100%;
        margin-top: 30px;
    
    }
    .toimisto-allImage img {
        border-radius: 10px;
    }
    .toimisto-allText {
       
        max-height: none !important
    }
    
    .map-list {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 30px;
        
    
    }
    .map-listSmall {
        margin-bottom: 40px;
        justify-content: center;
    }
    .small-list {
        text-align: center;
    }
    .toimistot img {
        height: 320px;
    }

}