
.show-blog {
    margin-top: 25px;    
    width: 500px;
    max-width: 500px;
    padding-bottom: 20px;
    
}

.show-blog h5 {
    margin-top: 60px;
    font-size: 40px;
    font-weight: 400;
    text-align: center;
    color: rgb(0, 0, 0);
}
.show-blog p {
    text-align: left;
    font-weight: 400;
    padding-top: 10px;
    
    color: rgb(19, 19, 19);
    margin-bottom: 6px;
}
.show-blog img {
    border-radius: 10px;
    width: 100%;
    max-height: 400px;
}
.blog-text h3 {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0px;
}
.blog-text p {
    max-width: 100%;
    
    text-align: center;
    margin-bottom: 40px;
    color: black;
    padding: 0px 40px;
    text-align: justify;

}
.blog-text1 strong {
    font-size: 22px;
}
.blog-text1 {
    padding: 0 !important;
    /* text-align: center; */
}
.blog-text1 p {
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
    color: black;
    padding: 0px;

}
.blog-text1 ul {
    /* display: inline-block; */
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    margin-left: 6%;
        /* For IE, the outcast */
    /* zoom:1; */
    /* display: inline; */
}
.blog-text1 h3 {
    max-width:100%;
    text-align: center;
    margin-bottom: 0px;
    color: black;
    padding: 20px 10px;
}

.item1 {
    background-color: #ffffff;
    margin: 0px;
    width: 100%;
}


  .photothumb1{
    width: 100%;
    max-height: 600px;
    margin-top: 0px;
    border-radius: 10px;
  }
  .item1 :hover {
      cursor: pointer;
      opacity: 0.9;
  }
 

  .item1 h3 {
      text-align: left;
      font-weight: 500;
      font-size: 24px;
  } 
  .item1 p {
      text-align: left;
      font-weight: 400;
      padding-top: 10px;
      font-size: medium;
      padding-bottom: 20px;
  }
  
 
  .photo1 .title1{
    background-color: #ffffff;
  }
  .desc1{  
    padding: 10px 10px 5px 10px;
  }
 
  .desc1 p{
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: left !important;
  }

  .grid1 {
    display: grid;
    grid-gap: 10px;
    
    grid-template-columns: repeat(auto-fill, minmax(300px,1fr));
 }

 /* DROPDOWN */
 .uutiset {
     display: flex;
 }
.dropdown_kirjeet {
    position: relative;
    display: inline-block;
    
}
.dropdown_btn {
    background-color: rgb(178, 13, 243);
    color: white;
    padding: 10px;
    font-size: 16px;
    border-radius: 10px;
    cursor: pointer;
}
.dropdown_content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}
.dropdown_content a {
    color: rgb(210, 37, 253);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
.dropdown_kirjeet:hover .dropdown_content {
    display: block;
}
  
 

 @media screen and (max-width: 800px){

    .show-blog {
        margin-top: 5px;
        width: 91%;
    }
    .show-blog h2 {
        font-size: 30px !important;
    }
    
    .events {
        max-width: 100%;
    }
    .item {
        width: 100%;
        
    }
    
    .blog-text1 p {
        width: 100%;
    
        margin-left: 0px;
        margin-right: 0px;
        text-align: center;
        margin-bottom: 10px;
        color: black;
        padding: 0px;
        text-align: left
    }
   
    .grid p {
      font-size: 15px;
    }
    .grid1 {
        grid-template-columns: repeat(auto-fill, minmax(230px,1fr)) !important;

    }
    .photothumb1 {
        max-height: 400px;
    }
    .dropdown_btn {
        padding: 10px;
        font-size: 14px;
        border-radius: 10px;
        cursor: pointer;
    }
}
