.info h2 {
    font-weight: 300;
    font-size: 35px;
    padding-bottom: 10px;
    text-align: left;
    max-width: 700px;
}


.info {
    margin-top: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    margin-bottom: 100px;
  
}
.info-text {
    text-align: left;
    max-width: 800px;
   
    
}
.info-list {
    width: 100%;
    
}
.johtokunta {
    width: 800px;
}



@media (max-width: 880px) {
    .info h2 {
        width: 100% !important;
        text-align: center;
        font-size: 30px;
    }
    .info {
        margin-top: 70px !important;
    }
    .info-text {
        padding: 2px 0px;

    }
    .johtokunta {
        width: 100%;
    }
}