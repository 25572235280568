.saavutettavuus {
    margin-top: 100px;
    text-align: center;
}
.saavutettavuus h2 {
    font-weight: 300; 
    font-size: 40px;
}
.saavutettavuus p {
    
        font-weight: 370;
        max-width: 800px;
        margin: auto;
        width: 100%;
        text-align: left;
    
    
}
.s-lista {
    list-style: square !important;
    padding-top: 20px;
    padding-left: 21%;
    max-width: 700px;
    margin-bottom: 20px;   
}
.s-lista {
    text-align: left;
}

@media screen and (max-width: 800px){
    .s-lista {
        padding-left: 10%;
    }

}
    