
  .accordion h4 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    padding: 1rem 3rem 1rem 1rem;
    color: #686868;
    font-size: 1.15rem;
    font-weight: 400;
    border-bottom: 1px solid #e5e5e5;
  }
  .accordion h4:hover,
  .accordion h4:hover::after {
    cursor: pointer;
    color: #222222;
  }
  .accordion h4:hover::after {
    border: 1px solid #ff5353;
  }
  .accordion h4.active {
    color: #eb53ff;
    border-bottom: 1px solid #eb53ff;
  }
  .accordion h4::after {
    font-family: 'Ionicons';
    content: '\f218';
    position: absolute;
    float: right;
    right: 0rem;
    font-size: 1rem;
    color: #080808;
    padding: 5px;
    width: 24px;
    height: 24px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid #b12be6;
    text-align: center;
  }
  .accordion h4.active::after {
    font-family: 'Ionicons';
    content: '\f209';
    color: #ff5353;
    border: 1px solid #ff5353;
  }
  .accordion .content-faq {
    opacity: 0;
    padding: 0 1rem;
    max-height: 0;
    border-bottom: 1px solid #e5e5e5;
    overflow: hidden;
    clear: both;
    -webkit-transition: all 0.1s ease 0.10s;
    -o-transition: all 0.1s ease 0.10s;
    transition: all 0.1s ease 0.10s;
  }
  .accordion .content-faq p {
    font-weight: 400;
    color: black;
    padding: 10px;
  }
  .accordion .content-faq.active {
    opacity: 1;
    padding: 1rem;
    max-height: 100%;
    -webkit-transition: all 0.20s ease 0.15s;
    -o-transition: all 0.20s ease 0.15s;
    transition: all 0.20s ease 0.15s;
  }

.content-faq ul {
    padding-left: 30px;
    
    max-width: 1000px;
}
.faq p {
    margin-top: 20px;
    font-size: 22px;
    
}
.faq .sub-list ul {
    padding-left: 30px;
}
.testi-sub {
    padding-left: 30px;
    margin-top: 10px;
    font-style: italic;
    
}
.testi-sub ul {
    list-style: square;

}
