#about {
	padding: 40px;
    text-align: center;
    margin-top: 79px;

}

#about p {
	font-size: 1.2rem;
	max-width: 700px;
    margin: auto;
    width: 100%;
}

#about h2 {
	margin: 30px 0;
	color: var(--primary-color);
}

.social a {
    margin: 0 5px;
    
    color:#7cdaff;

}
.btn-info {
    cursor: pointer;
    display: inline-block;
    padding: 10px 30px;
    font-size: large;
    border-radius: 5px;
    background-color: black;
    color: white;
    margin-top: 25px;
    

}
@media screen and (max-width: 800px){
    #about p {
        font-size: 1.04rem !important;
        width: 100% !important;
       
       
    }
    #about {
        margin-top: 50px;
    }

}
