.title-color {
    /*background: linear-gradient(100deg, rgb(255, 255, 255) 50%, rgb(64, 130, 246) 50%); */
    background-color: #7ca3ff;
    height: 200px;
    margin-bottom: 40px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vkl {
    display: -ms-flexbox; /* IE10 */
    display: flex;
   -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    height: auto;
    justify-content: space-around;
   
   
}
.color {
    background-color:rgb(247, 247, 247);
    height: 500px;
}
.color2 {
    background-color:#7cdaff;
    height: auto;
    

}
.vkl-text {
    text-align: center;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    
    -ms-flex: 50%; /* IE10 */
    flex: 50%;
    color: rgb(0, 0, 0);
    
    
}

.vkl-text p {
    width: 70%;
    max-width: 400px;
}

.vkl-img {
   flex: 50%;
   padding-top: 30px; 
  
   text-align: center;
   
   
   
   
}
.vkl-img img {
    padding-left: 0px;
    width: 450px;
    height: 450px;
    
}
.vkl .gallery {
    margin: 5px;
   
    
}
.im-row {
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
    /*padding: 0 3px;
    -ms-flex: 30%; /* IE10 */
    flex: 50%;
    
    
  }
  
  /* Create four equal columns that sits next to each other */
  .column {
    flex: 25%;
    max-width: 45%;
    padding: 0 4px;
  }
  
  .column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }
div.gallery img {
    width: 100%;
    height: auto;
}
.vkl-text h3 {
    margin-bottom: 10px;
}

.avoin {
    display: flex;
    justify-content: center;
    margin-top: 0px;

   
}
.text-right {
    text-align: center;
   
    flex: 100%;
    
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.text-right p {
    width: 70%;
    max-width: 400px;
}

.muskari {
    display: flex;
    justify-content: center;
    
  
    
}
.muskari-img {
    flex: 50%;
    -ms-flex: 50%; /* IE10 */
    padding: 20px;
    width: 100%;
    height: 100%;
    max-height: 500px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
}
.muskari-text {
    flex: 50%;
    -ms-flex: 50%; /* IE10 */
    height: 60vh;
    max-height: 500px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color:#6b9df9;
    
    
  
}
.muskari-text:hover {
    cursor: pointer;
}
.muskari-text p {
    width: 70%;
    max-width: 400px;
}
.btn-litle {
    cursor: pointer;
    padding: 10px 20px;
    font-size: large;
    background: rgb(165, 36, 207);
    border-radius: 5px;
    margin-top: 10px;
    color: white;
    
}
.btn-litle :hover {
    font-size: medium;
}





@media(max-width: 800px) {
   .avoin {
       display: flex;
       flex-direction: column;
   }
   .vkl {
       display: flex;
       flex-direction: column;
       height: auto;
   }
   .im-row {
    margin-top: 0px;
    
    padding: 0 3px; 
   }
   .muskari-text  {
        width: 100%;
   }
   
   .muskari {
       display: flex;
       flex-direction: column;
   }
   .muskari-img img {
       margin-top: 10px;
       height: 320px;
       
   }
   .muskari-img {
       text-align: center;
   }
  
   .small img {
       height: auto;

   }
   .vkl-text {
       padding-top: 20px;
       width: 100% !important;
       padding-bottom: 20px;
   }
   .vkl-text p {

    width: 90% !important;
   }
   
   .muskari-text {
    padding-top: 20px;
    width: 100% !important;
    padding-bottom: 20px;
   }
   .muskari-text p {
    width: 90% !important;
   }

  
   div.gallery img {
    display: flex;
    flex-direction: row;   
    width: 100%;
    height: 20px;
   }
   .column {
    flex: 50%;
    max-width: 50%;
   }
   .text-right p {
     width: 90% !important;
    
    }
   
    
}
@media(max-width: 1000px) {
    .vkl-text {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
@media(max-width: 1100px) {
    .vkl-img img {
        width: 400px;
        height: 400px;
    }
    .vkl-img {
        padding-top: 40px !important;
    }
   
}
@media(max-width: 947px) {
    .vkl-img img {
        width: 320px;
        height: 320px;
    }
    .vkl-img {
        padding-top: 40px !important;
    }
   
}
@media(max-width: 900px) {
    .vkl {
        display: flex;
        flex-direction: column;
        height: auto;
    }
    .im-row {
        margin-bottom: 0px;
    }
    
    .color {
        background-color:rgb(247, 247, 247);

        height: auto;
    }
    .color2 {
        background-color:#7cdaff;
        height: auto;
        
    
    }
}
@media(max-width: 500){
    .vkl-img img {
        width: 300;
        height: 300;
    }
}