
.main-konsertti {
  
    padding: 0px;
    height: auto;
    
   
}
.konsertti-box {
    display: -ms-flexbox; /* IE10 */
    display: flex;
   
    flex-wrap: wrap;
    font-size: 30px;
    text-align: center;
    max-height: 600px;
    
}
.konsertti-image {
   
   flex: 50%;

}
.konsertti-image img {
    height: 100%;
    width: 100%;
    max-height: 600px;
    
}

.konsertti-text {
    flex: 50%;
    text-align: center;
    background-color: rgb(35, 35, 35);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 253, 253);

}
.konsertti-words h2 {
    font-size: 50px;
    font-weight: 300;
    
    
}

.Typewriter__wrapper {
    font-size: 50px;
    font-weight: 300;
}
.Typewriter__cursor {
    font-size: 50px;
    font-weight: 300;
}


@media (max-width: 800px) {
    .konsertti-image, .konsertti-text {
      flex: 100%;
    }
    .konsertti-text {
        padding: 20px;
        height: 240px;
    }
  }