
.konsertit-main  {
    width: 100%;
    height: auto;
    margin-top: 50px;

    background-color: rgb(42, 42, 42);
    color: white;
    padding: 30px;
    background-image: url(../images/guitar.png);
    background-repeat:space;
    background-size:cover;    
}
.konsertit-h2 {
    text-align: center; 
    margin-top: 50px;
    margin-bottom: 20px;
    font-weight: 300;
    text-decoration: none;
    font-size: 50px;
}
.konsertit-accordion {
    padding-bottom: 5vh;
    padding-top: 4vh;
}
.konsertit-accordion h4 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    padding: 1rem 3rem 0.4rem 1rem;
    color: #050505;
    font-size: 1.09rem;
    font-weight: 400;
    border-bottom: 1px solid #e5e5e5;
  }
.konsertit-accordion h4:hover,
.konsertit-accordion h4:hover::after {
    cursor: pointer;
    color: #484747;
    animation: border-animation 0.2s ease-in-out; 

}
/*
.konsertit-accordion h4:hover::after {
    border: 1px solid #3f3c3c;
  }

.konsertit-accordion h4.active {
    color: #9d32d2;
    animation: border-animation 0.2s ease-in-out; 


}
*/


@keyframes border-animation {
    from {
        color: black;
    }
    to {
        color: #19181a;
    }
}
/*
.konsertit-accordion h4::after {
    font-family: 'Ionicons';
    content: '\f218';
    position: absolute;
    float: right;
    right: 0rem;
    font-size: 1rem;
    color: #080808;
    padding: 5px;
    width: 24px;
    height: 24px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid #b12be6;
    text-align: center;
    margin-top: 1vh;
  }
.konsertit-accordion h4.active::after {
    font-family: 'Ionicons';
    content: '\f209';
    color: #d127e4;
    border: 1px solid  #9d32d2;
  }
.konsertit-accordion .konsertit-content-faq {
    opacity: 0;
    padding: 0 1rem;
    max-height: 0;
    border-bottom: 1px solid #e5e5e5;
    overflow: hidden;
    clear: both;
    -webkit-transition: all 0.2s ease 0.10s;
    -o-transition: all 0.2s ease 0.10s;
    transition: all 0.2s ease 0.10s;
  }
.konsertit-accordion .konsertit-content-faq p {
    font-weight: 400;
    color: black;
    padding: 10px;
}
.konsertit-accordion .konsertit-content-faq.active {
    opacity: 1;
    padding: 1rem;
    max-height: 100%;
    -webkit-transition: all 0.2s ease 0.15s;
    -o-transition: all 0.2s ease 0.15s;
    transition: all 0.2s ease 0.15s;
}
*/
.konsertit-setlist {
    display: flex;
    flex-direction: row;
}
.konsertit-setlist p {
    font-size: 0.9rem;
}

.content-faq ul {
    padding-left: 30px;
    max-width: 1000px;
}
.faq p {
    margin-top: 20px;
    font-size: 22px;
    
}
.faq .sub-list ul {
    padding-left: 30px;
}
.testi-sub {
    padding-left: 30px;
    margin-top: 10px;
    font-style: italic;
    
}
.testi-sub ul {
    list-style: square;

}
.konsertit-error h2 {
    font-weight: 300;
    font-size: 40px;
    line-height: 1.2;
}
@media(max-width: 800px) {
    .konsertit-accordion {
        padding-top: 2vh;
    
    }
    .konsertit-accordion h4 {
        font-size: 0.99rem;
        font-weight: 400;
        padding: 1rem 1.2rem 1rem 0rem !important;
    }
    /*
    .konsertit-accordion h4::after {
        font-family: 'Ionicons';
        content: '\f218';
        position: absolute;
        float: right;
        right: 0rem;
        font-size: 1rem;
        color: #080808;
        padding: 1px;
        width: 24px;
        height: 24px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        border: 1px solid #b12be6;
        text-align: center;
        margin-top: 1vh;
        
    }
    */

    .konsertit-h2 {
        margin-top: 10px !important;
        margin-bottom: 10px;
        font-weight: 300;
        text-decoration: none;
        font-size: 35px;
    }

}

/*
.style-mark {
    display: flex;
    width: 100%;
    justify-content:center;
    align-items:center;
}


.style-mark strong {
    font-size: 20px;
    font-weight: 500;
    font-style: italic;
}
.mark p {
    font-size: 17px;
    margin-top: 22px;
}
.style-mark br {
    content: "";
    margin: 2em;
    display: block;
    font-size: 24%;
}
@-moz-document url-prefix() {
  .style-mark br {

    content: "";
    margin: 2em;
    display: block;
    font-size: 24%; 
}
}
*/
/*
.style-mark ol {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-shrink: 3;
     
    grid-gap: 5px;
}
.style-mark li {
    list-style: none;
    padding: 20px;
    margin-bottom: 10px;
    max-width: 459px !important;
    background-color: rgb(249, 249, 249);
    border-radius: 10px;
    box-shadow: 11px 7px 10px -3px rgba(0,0,0,0.32);
    -webkit-box-shadow: 11px 7px 10px -3px rgba(0,0,0,0.32);
    -moz-box-shadow: 11px 7px 10px -3px rgba(0,0,0,0.32);
    max-width: 60vh;
}
*/

/*
.style-mark li :nth-child(1){
    transition-delay: 200ms;
}


@media screen and (max-width: 910px){
    .konsertit-main  {
        width: 100% !important;
        height: auto;
        margin-top: 1px;
        padding: 10px;
        background-image: none;
     
    
        
    }
    .mark p {
        font-size: 15px;
        margin-top: 22px;
    }
    .konsertit {
        padding: 0px;
        margin-top: 3rem;
        margin-bottom: 4rem;
    }
}
    */