HTML CSSResult

.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}

/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.clearfix {
    zoom: 1;
}
.clearfix {
  margin: auto;
  width: 80%;

  
}

body {
  font-family: "Helvetica Neue", Helvetica, arial, freesans, clean, sans-serif;
  color: #201f1f;
 
}

/* Start the styles  */
p {
  line-height: 1.5;
}
.wrapper h1 {
  font-weight: 200;
}

.wrapper h2 {
  margin-top: -15px;
  font-weight: 400;
  line-height: 1.2;
}
.wrapper {
  padding: 30px 0px 30px 0px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 100px;

  
}
.muskari-back {
  background-color: rgb(255, 255, 255);
}
.circle {
  background-repeat: no-repeat;
  width: 400px;
  height: 400px;
  /* background-color: #dc1be2; */
  background: url('../images/muskari2022.jpg') left no-repeat;
  border-radius: 50%;
  float: left;
  shape-outside: circle() margin-box;
  margin: 10px;
}
.wrapper p {
  max-width: 800px;
}

.muskari1-text {
    display: flex;
    justify-content: space-between;
    text-align: left;
    background: url('../images/musikantti.png') no-repeat center;
    background-size: 70vh;
    opacity: 1;
    border-radius: 20px;
    padding-bottom: 80px;
    flex-direction: row;
       
}

.muskari1-text h2 {
    color: black;
    margin-bottom: 5px;
    padding: 20px;

}
.perhe-ball p {
    padding: 20px;
}
.muskarit1 p {
    padding: 20px;
}
.musiikki1 p {
    padding: 20px;
}
.muskari-hinnat {
  color: rgb(210, 6, 218);
  font-size: 22px;
  margin-top: 10px;
}
.muskari-hinnat :hover {
  cursor: pointer;
  opacity: 0.8;
}


@media(max-width: 920px) {
      .wrapper {
        padding: 20px 0px 0px 0px;
        margin-bottom: 30px;
        max-width: 100%;
        margin-top: 50px;


      }
      .muskari1-text {
        display: flex;
        justify-content: space-between;
        text-align: left;
        background: url('../images/musikantti.png') no-repeat center;
        opacity: 1;
        border-radius: 20px;
        padding-bottom: 30px;
        flex-direction: column;
           
      }
      .perhe-ball p {
        padding: 0px;
      }
      .muskarit1 p {
        padding: 0px;
      }
      .musiikki1 p {
        padding: 0px;
      }
      .muskari1-text h2 {
        color: black;
        margin-top: 50px;
        margin-bottom: 10px;
        padding: 0px;
        
    
     }
      .clearfix {
        width: 100%;
      
      }
     
      .wrapper h1 {
        color:black;
        margin-top: 20px;
        
        font-weight: 200 !important;

      }
     
      .circle {
        float: none;
        height: 0px;
        margin-left: 0%;
        width: auto;
        max-width: 320px;
        border-radius: 50%;
        background: none ;
        background-position: right;
        
      


        
       
      }


}


/* Modal */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  
  border-radius: 12px;
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  font-weight: 200;
  border: 1px solid #888;
  width: 90%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-list {
  padding: 20px;
}


.muskari {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  

}

.muskari-card {
  
  width: 33%;
  height: auto;
  flex-basis: 33.3333;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  gap: 10px;
  margin-top: 20px;
  border-radius: 10px;

  
}
.muskari-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.muskari-card  img {
  filter: contrast(1.03);
  height: 300px;
  width: 100%;
  border-radius: 10px 10px 0 0;
  object-fit: cover
    
}

.muskari-card-text {
  padding-top: 10px;
  text-align: left;
  height: 300px;
  max-width: 400px; 
  padding: 20px;
}
@media(max-width: 1200px) {
  .clearfix {
    width: 100%;
  }
  .muskari {
    flex-direction: row;
  }
  .muskari-card {
    width: 100% !important;
    max-width: 320px;
  
    
  }
  .muskari-card  img {
    height: 290px;
    width: 100%;
    border-radius: 10px 10px 0 0;
    flex-basis: 50.3333;

      
  }
  .muskari-card-text {
    height: 365px;
  }

}

@media(max-width: 980px) {
  .clearfix {
    width: 100%;
  }
  .muskari {
    flex-direction: row;
  }
  .muskari-card {
    width: 100% !important;
    max-width: 350px;
  
    
  }
  .muskari-card  img {
    height: 290px;
    width: 100%;
    border-radius: 10px 10px 0 0;
    flex-basis: 50.3333;

      
  }
  .muskari-card-text {
    height: 350px;
  }

}

@media(max-width: 570px) {
    .muskari-card {
      max-width: 100%;
      height: auto
    }
    .muskari-card-text {
      height: auto;
    }


}