


 .footer-oma {
     position: fixed;
     bottom: 0px;
     text-align: center;
     padding-top: 0px;
     background: url('../images/guitar.png');
 }
 .footer-oma h3 {
     font-size: 25px;
     font-weight: lighter;
 }
 
 .footer .icon-bar {
     padding: 35px;

 }
 .footer .icon-bar a {
    margin: 2px 2px;
    padding: 10px;
    font-size: 17px;
    width: 1px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;    
}
.footer .icon-bar a:hover {
    opacity: 0.8;
    background-color: rgb(57, 59, 61);
    color: rgb(252, 252, 252);

}
.footer-rest {
    color: rgb(252, 250, 250);
    
    background: url('../images/logo.png') no-repeat center;
    background-color: rgb(40, 40, 40);
    padding-top: 2%;
    padding-bottom: 2%;
    display: grid;
    grid-template-columns: 5% 1fr 1fr 1fr 5%;
    
    margin-top: 0px;
   

}
.footer-rest u {
    text-decoration: underline;
}
.footer-site {
    grid-column: 2/3;
    text-align: center;
}
.footer-site .main-menu {
    transition: all .5s ease-in;
}
.footer-site .main-menu a{
    color: white;
    text-decoration: none;
}

.footer-site .main-menu li:hover {
    opacity: 0.8;
    cursor: pointer;
    text-decoration: underline;
    /*transform: scale(1.1);*/

}
.footer-contacts {
    
    text-align: center;
}
.footer-extras {
    text-align: center;
   

}
.footer-rest h3 {
    font-size: 25px;
    font-weight: lighter;
    
}
.footer-rest p {
    font-weight: 300;
    font-size: 15px;
}


 .footer-social {
     text-align: center;
     padding-top: 20px;
     
 }
 .footer-end {
     text-align: center;
     padding-top: 20px;
     
 }
.main-menu {
    list-style: none;
}

  
@media(max-width: 800px) {
   
 
  
   
    .footer .footer-rest {
      color: rgb(224, 218, 224);
     
     
      padding-top: 20px;
      grid-template-columns: none;
      display: flex;
      flex-direction: column;
      padding-bottom: 35px;
  }
    
    .footer-rest h3 {
      text-decoration: underline;
      font-weight: 400;
      
    }
    .footer-site a {
        cursor: pointer;
    }
   
   
    .footer-site {
      margin-bottom: 10px;
      padding: 10px;
      margin-top: -16px;
    }
    .footer-contacts {
      margin-bottom: 10px;
    
    }
    .footer-contacts p {
        font-size: 16px;
        font-weight: 300;
    }
    .footer-extras {
      padding: 5px;
      position: relative;
    }
  }
   
  
  
  