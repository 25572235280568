.opettajat p {
    font-weight: 350;
    margin-top: 3px;
    line-height: 1.625em;
    font-size: 15px;
}
.opeTable{
    width: 100%;
    padding-bottom: 30px;
}


.opeTable tr:nth-child(even) {background-color: #efefef;}
