.laskutus {
    margin-bottom: 0px;
    height: 90vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}
.laskutus-image {
    width: 80%;
}
.laskutus-image img {
    width: 100%;
    
}


.laskutus-text {
    margin-top: 0px;
    width: 100%;
    padding-left: 20px;
    text-align: left;

}

@media screen and (max-width: 800px){
     .laskutus-text {
         padding-left: 0px;
         width: 90%;
     }
     .laskutus-text p {
         max-width: 90%;
         padding-right: 20px;
     }
    

}