.item {
    background-color: #ffffff;
    margin: 0px;
    width: 100%;
    border-radius: 8px;
    
    
}
.item2 {
   background: url('../images/Pallo.png') center fixed no-repeat
}
.events {
    background-color: #fdfafa;
    margin: 10Px;
    width: 160%;
}
.list {
    padding: 0px;
    padding-left: 0%; 
    
}
/*
.list li:hover {
  background-color: #f0f0f0;
}
*/
/*
.list li:nth-child(odd) {
  background-color: #c250bc;
  color: white;
}
*/
.news-event {
  padding-bottom: 0px;
}
/*
.list ol {
    list-style: none;
    
  
}
.list li {
  padding: 5px 10px;
  background-color: #fcfafa;
  border: 1px solid #ececec;

}
*/

  .photothumb{
    width: 100%;
    max-width: 500px;
    margin-top: 0px;
    border-radius: 8px 8px 0px 0px;
    height: 200px;
    object-fit: cover
  }
.title {
    padding: 8px;
    text-align: center;
        
}
.title2 {
  padding-top: 2px;
}
.title2 h3 {
  font-weight: 500;
}
/* List */
.item2 ul {
  counter-reset: index;  
  padding: 0;
  width: 100%;
 /* max-width: 300px; */

}

/* List element */
.item2 li {
  counter-increment: index; 
  display: flex;
  align-items: center;
  padding: 5px 0;
  box-sizing: border-box;
  width: 100%;
}
.item2 li strong {
  margin-right: 10px;
}
.item2 li::before {
  content: counters(index, ".", decimal-leading-zero);
  font-size: 1.2rem;
  text-align: none;
  font-weight: bold;
  min-width: 40px;
  padding-right: -12px;
  font-variant-numeric: tabular-nums;
  align-self: flex-start;
  background-image: linear-gradient(to top, #cc02ff, #d642fd, #de61fb, #e47cfa, #f2b1fc);
  background-attachment: fixed;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


  .title h3{
    
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 900;
  }
  .project .title{
    background-color: #607D8B;
  }
  .blog .title{
    background-color: #ff9800;
  }
 
  .photo .title{
    background-color: rgb(204, 67, 223);
    height: 70px;
    border-radius: 10px 10px 0px 0px;
    
  }
  .photo .title h3 {
    font-weight: 500;
    width: 100%;
    top: 0;
    
  }
  .photo p {
    color: #4a4949;
  }
  .backNews {
    background-color: rgb(255, 255, 255);
    padding-bottom: 36px;
  }
  .onclick-image {
    cursor: pointer;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(350px,1fr));    
  }
  .onclick-image :hover {
    opacity: 0.95;
  }
  .desc{
    padding: 10px 10px 5px 2px;
  }
  .desc img{
      
    width: 50%;
    margin: 0 10px 10px 0;
    float: left;
  }
  .desc p{
    margin-bottom: 10px;
  }

  .readMore {
    padding-top: 20px;
    text-align: center;
  }
  .shows br {
    list-style: square;
    
  }
  .grid {
   
    
 }
 .btn1 {
  display: inline-block;
  border-radius: 8px;
  background-image: linear-gradient(to right, #cc43df, #d95ee6, #e677ee, #f28ef5, #fda5fd);  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 20px;
  padding: 13px;
  height: 45px;
  
  width: auto;
  transition: all 0.5s;
  cursor: pointer;
  margin: 0px;
  text-align: center;
  right: 0;
}

.btn1 span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.btn1 span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.btn1:hover span {
  padding-right: 25px;
}

.btn1:hover span:after {
  opacity: 1;
  right: 0;
}
 @media screen and (max-width: 800px){
    .events {
        max-width: 100%;
    }
    .item {
        width: 100%;
        
    }
    .title h3 {
      font-size: 17px;
    }
    .grid p {
      font-size: 15px;
    }
    .photo .title{
      background-color:rgb(204, 67, 223);
      height: 60px;
    }
    .btn1 {
      height: 45px;
      font-size: 18px;
    }
    .list {
      padding: 0px;
      padding-left: 0%;
 
    }
    .photothumb {
      height: auto;
    }

    .item2 li {
      counter-increment: index; 
      display: table-row-group;
      align-items: center;
      padding: 3px 0;
      box-sizing: border-box;
      width: 100%;
    
        
    }
    .item2 li strong {
      margin-right: 5px;
      width: 100%;
      margin-top: 1px;
    }
    .item2 li::before {
      content: none;
      font-size: 1rem;
      text-align: center;
      font-weight: bold;
      min-width: 30px;
     
     
    }
    
}