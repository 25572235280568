.anchor-links {
    height: auto;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: 10px;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    /*
    position: fixed;
    width: 100%;
    */
    
}
.anchor-links button {
    
    color: rgb(210, 6, 218);

    font-size: 18px;
    cursor: pointer;
    padding: 0;
    border: none;
    background: none;
    padding-top: 10px;
}

/* Dropdown button */
.dropdown2 {
        float: left;
        overflow: hidden;
        margin-top: 0px;
    
 }
 
 .dropbtn2 .dropdown2:hover {
    color: blueviolet;

 }
  
  .dropdown2 .dropbtn2 {
    font-size: 18px;    
    border: none;
    outline: none;
    /*padding: 14px 12px */;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
    cursor: pointer;
    
  }
  .dropdown2-content2 button {
    float: none;
    color: black;
    padding: 10px 10px;
    text-decoration: none;
    display: block;
    text-align: left;
    color: black;
    
  }
  .dropdown2-content2 button:hover {
    color: blueviolet;
   }
  .dropdown2-content2 {
    display: none;
    position: absolute;
   
    background-color: rgb(255, 255, 255);
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 11;
    border-radius: 8px;
  }
  /* Onclik button show */
  .show2 {
    display: block;
  }

  .opiskelu-list  {
    padding-top: 10px;
    padding-left: 3%;
  }
.opiskelu-image {
    width: 100%;
    border-radius: 10px;
    text-align: center;
    
}
.opiskelu-image img {
    width: 100%;
}

.opiskelu-1 {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0%;
}

.titles-opinnot {
    padding: 0px 10px;
    
}


.opiskelu-1-text {
    padding: 0px 10px;
    max-width: 100%;
}
.opiskelu-1-text p {
    padding-top: 2%;
}
.instrumentit {
    display: flex;
    flex-direction: row;
    
}
.more-margin {
    padding-top: 30px;
    padding-bottom: 30px;
}
.borders-image img {
    border-radius: 20px;
    
}
.opiskelu-2-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
}
.puu-kuva {
    height: 100%;
    width: 100%;
}
.opiskelu-1 img {
   
    max-height: 700px;
    max-width: 900px;
}

.opiskelu-2 {
    height: 100vh;
}

.opiskelu-3 {
    height: 100vh;
}
.link-button:hover{
    cursor:pointer;
    color:rgb(239, 39, 213)
}
.link-button {
    font-size: 18px;
    background-color: white
}
.aikuiset-img  {
    margin-top: 8%;
    width: 100%;
    height: auto;
}
.aikuiset-img img {
    width: 100%;
    max-height: 500px;
    border-radius: 10px;
}
.padding-image {
    padding-top: 30px;
}

.title-h2 {
    font-weight: 200;
    font-size: 47px;
    line-height: 1.2;
    

}
.ownMarkdown {
    padding: 0px;
    display: flex;
    flex-direction: column;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
    pointer-events: none;
    
  }
  .ownMarkdown p {
    max-width: auto;
    color: #ffffffda;
    padding: 1.5rem;
    background-color: #7cc6f7;
    border-radius: 10px;
    color: black;
    pointer-events: auto;
  }


@media (max-width: 910px) {
    .anchor-links {
        height: auto;
        padding-top: 1.2rem;
        display: flex;
        align-items:  flex-start;
        justify-content: space-evenly !important;
    }
    .instrumentit {
        flex-direction: column !important;
    }
    .puu-kuva img {
        width: 100% !important;
    }
    .padding-image {
        padding-top: 30px;
    }
    .aikuiset-img img {
        max-height: 400px;
    }
    .title-h2 {
        font-size: 50px;
    }
    .opiskelu-1-text {
        padding: 0px 0px;
        
    }
    .ownMarkdown {
        display: flex !important;
        flex-direction: none !important;
        
      }
      .ownMarkdown p {
        width: 100% !important;
        padding: 0.8rem;
    }
    .opiskelu-2-text {
        padding: 0px !important;
        width: 100%;
    }
}

@media (max-width: 742px) {
    .anchor-links {
        height: auto;
        padding: 0px;
        padding-top: 1.3rem;
        font-size: 17px !important;
        justify-content: space-around;
        align-items: flex-start;
        flex-direction: column !important;
   
    }
    .anchor-links button {
        color: rgb(210, 6, 218);
        padding-top: 6px;
    }
    .opiskelu-2-text {
        padding: 0px !important;
        width: 100%;
    }

    .dropdown2-content2 {
        margin-top: 10px;
        left: 2%;
       
      
      }
    .opiskelu-list  {
        padding-left: 5%;
    }
    .padding-image {
        padding-top: 10px;
    }
    .title-h2 {
        font-size: 45px;
    }
    .opiskelu-1-text {
        padding: 0px 0px;
        
    }
}

